.classicButton {
  display: block;
  width: 181px;
  position: relative;
  height: fit-content;
  padding-top: 6px;
  padding-bottom: 6px;
}

.classicButton::before {
  display: block;
  content: " ";
  position: absolute;
  left: 1rem;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    30deg,
    rgba(255, 116, 3, 0.42) 33%,
    #ff7400 55%,
    rgba(255, 116, 3, 0.42) 66%
  );
  background-size: 300% 100%;
  z-index: -1;
  clip-path: polygon(0 0, 100% 0, 100% 47%, 75% 100%, 0 100%, 0 0);

  -moz-box-shadow: inset 0 -2px 0 rgba(255, 116, 3, 0.12);
  -webkit-box-shadow: inset 0 -2px 0 rgba(255, 116, 3, 0.12);
  box-shadow: inset 0 -2px 0 rgba(255, 116, 3, 0.12);

  -webkit-animation: Animation 2s ease-out infinite;
  -moz-animation: Animation 2s ease-out infinite;
  animation: Animation 2s ease-out infinite;
}

.classicButton:hover::before {
  background: #ff7400;
  -webkit-animation: none;
  -moz-animation: none;
  animation: none;
}

@media only screen and (max-width: 600px) {
  .classicButton {
    width: 100%;
    right: 1rem;
  }

  .classicButton:before {
    left: -.5rem;
    width: 135%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .classicButton {
    width: 100%;
    right: 0;
    white-space: nowrap;
    font-size: 1rem!important;
  }

  .classicButton:before {
    left: -.5rem;
    width: 135%;
  }
}

@-webkit-keyframes Animation {
  0% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
}
@-moz-keyframes Animation {
  0% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
}
@keyframes Animation {
  0% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
}
